/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */
import React from 'react';

import {
  MapBannerEditBlock,
  MapBannerViewBlock,
  TeamsViewBlock,
  TeamsEditBlock,
  FiguresViewBlock,
  FiguresEditBlock,
  ServicesListViewBlock,
  ServicesListEditBlock,
  ProjectsListingTemplate,
  ClientCarouselTemplate,
  ProjectView,
  ClientView,
  ValuesViewBlock,
  ValuesEditBlock,
  ServiceFullViewBlock,
  ServiceFullEditBlock,
  ProductView,
} from './components';

import { BlockButton } from '@plone/volto-slate/editor/ui';

import { ButtonCTA } from './styles';

import blocks from './styles/ToHTML';

import { Separator } from 'draft-js-inline-toolbar-plugin';
import sliderSVG from '@plone/volto/icons/slider.svg';
import groupSVG from '@plone/volto/icons/group.svg';
import imageSVG from '@plone/volto/icons/image.svg';
import ctaBtn from './icons/cta-btn.svg';

// import {installDemoStyles} from '@eeacms/volto-block-style';

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

export default function applyConfig(config) {
  // Add here your project's configuration here by modifying `config` accordingly

  // config = installDemoStyles(config);
  // config.settings.bbb_listingBlockFetchesFullobjects = true;

  // config.settings.richTextEditorInlineToolbarButtons = [
  //   ...config.settings.richTextEditorInlineToolbarButtons,
  //   Separator,
  //   ButtonCTA,
  // ];

  config.settings.slate.buttons['ButtonCTA'] = (props) => (
    <BlockButton
      title="button-CTA"
      format="buttonCTA"
      icon={ctaBtn}
      {...props}
    />
  );

  config.settings.slate.elements = {
    ...config.settings.slate.elements,
    buttonCTA: ({ attributes, children }) => (
      <div className="buttonCTA" {...attributes}>
        {children}
      </div>
    ),
  };

  config.settings.slate.toolbarButtons = [
    ...config.settings.slate.toolbarButtons,
    'separator',
    'ButtonCTA',
  ];

  // config.settings.ToHTMLRenderers.blocks = {
  //   ...config.settings.ToHTMLRenderers.blocks,
  //   ...blocks,
  // };

  config.views.contentTypesViews.Project = ProjectView;
  config.views.contentTypesViews.Client = ClientView;
  config.views.contentTypesViews.Product = ProductView;

  config.blocks.blocksConfig['ServicesList'] = {
    id: 'ServicesList',
    title: 'ServicesList',
    icon: imageSVG,
    group: 'common',
    view: ServicesListViewBlock,
    edit: ServicesListEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig['MapBanner'] = {
    id: 'MapBanner',
    title: 'MapBanner',
    icon: imageSVG,
    group: 'common',
    view: MapBannerViewBlock,
    edit: MapBannerEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig['Teams'] = {
    id: 'Teams',
    title: 'Teams',
    icon: groupSVG,
    group: 'common',
    view: TeamsViewBlock,
    edit: TeamsEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig['figures'] = {
    id: 'figures',
    title: 'Figures',
    icon: sliderSVG,
    group: 'common',
    view: FiguresViewBlock,
    edit: FiguresEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig['Values'] = {
    id: 'Values',
    title: 'Values',
    icon: groupSVG,
    group: 'common',
    view: ValuesViewBlock,
    edit: ValuesEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig['ServiceFull'] = {
    id: 'ServiceFull',
    title: 'Service Hero',
    icon: groupSVG,
    group: 'common',
    view: ServiceFullViewBlock,
    edit: ServiceFullEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.listing['variations'] = [
    ...config.blocks.blocksConfig.listing.variations,
    {
      id: 'projectslist',
      isDefault: true,
      title: 'Projectslist',
      template: ProjectsListingTemplate,
    },
    {
      id: 'client_carousel',
      isDefault: true,
      title: 'Client Carousel',
      template: ClientCarouselTemplate,
    },
  ];

  return config;
}
