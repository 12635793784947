import React, { Component } from 'react';
import { SidebarPortal } from '@plone/volto/components';
import ServicesListData from './ServicesListData';
import ServicesListBody from './ServicesListBody';

class Edit extends Component {
  render() { 
    return (
      <>
      ServiceList
      <ServicesListBody data={this.props.data} id={this.props.block} />
      <SidebarPortal selected={this.props.selected}>
        <ServicesListData
            key={this.props.block}
            {...this.props}
            data={this.props.data}
            block={this.props.block}
            onChangeBlock={this.props.onChangeBlock}
          />
        </SidebarPortal>
      </>
    );
  }
}
 
export default Edit;