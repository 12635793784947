import React, { Component } from 'react';
import { SidebarPortal } from '@plone/volto/components';
import ValuesData from './ValuesData';
import ValuesBody from './ValuesBody';

class Edit extends Component {
  render() {
    return (
      <>
      Values
      <ValuesBody data={this.props.data} id={this.props.block} />
      <SidebarPortal selected={this.props.selected}>
        <ValuesData
            key={this.props.block}
            {...this.props}
            data={this.props.data}
            block={this.props.block}
            onChangeBlock={this.props.onChangeBlock}
          />
        </SidebarPortal>
      </>
    );
  }
}

export default Edit;