export const MapBannerSchema = (props) => {
  return {
    title: 'Map data',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['view_long', 'view_lat', 'pin_long', 'pin_lat', 'zomm', 'url'],
      },
    ],

    properties: {
      view_long: {
        title: 'View Longitude',
        type: 'number',
      },
      view_lat: {
        title: 'View Latitude',
        type: 'number',
      },
      pin_long: {
        title: 'Pin Longitude',
        type: 'number',
      },
      pin_lat: {
        title: 'Pin Latitude',
        type: 'number',
      },
      zoom: {
        title: 'Zoom',
        type: 'number',
        default: 15,
      },
      url: {
        title: 'Pin URL',
      },
    },
    required: ['pin_lat', 'pin_long'],
  };
};
