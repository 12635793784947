import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import { getContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Item } from 'semantic-ui-react';

const TeamsBody = ({ data, id, isEditMode, intl }) => {
  return (
    <Card.Group itemsPerRow={3} className="Teams">
      {data.teamsItem?.map((result) => (
        <Card key={result.id}>
          <Card.Content>
            <Card.Header textAlign="center">{result.title}</Card.Header>
            {result.person_function && (
              <Card.Meta textAlign="center">{result.person_function}</Card.Meta>
            )}
          </Card.Content>
        </Card>
      ))}
      {data.joinUs?.map((result) => (
        <Card key={result.id} href={result.url} className="joinus">
          <Card.Content>
            <Card.Header textAlign="center">
              <div className="header-content">{result.title}</div>
            </Card.Header>
          </Card.Content>
        </Card>
      ))}
    </Card.Group>
  );
};

export default TeamsBody;
