/**
 * View image block.
 * @module components/manage/Blocks/Hero/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { flattenToAppURL } from '@plone/volto/helpers';
import { LinkMore } from '@plone/volto/components';
import { removeSpaceFromAnchor } from '~/helpers';

/**
 * View image block class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => {
  return (
    <div className={`${data.isReverse && 'reverse'} block hero`}>
      <div className="block-inner-wrapper">
        {data.url && (
          <img
            src={`${flattenToAppURL(data.url)}/@@images/image`}
            alt=""
            className="hero-image"
            loading="lazy"
          />
        )}
        <div className="hero-body">
          <div className="hero-text">
            {data.title && (
              <h1 id={data.anchorId && removeSpaceFromAnchor(data.anchorId)}>
                {data.title}
              </h1>
            )}
            {data.richDescription && (
              <div
                dangerouslySetInnerHTML={{ __html: data.richDescription.data }}
              />
            )}
          </div>
          <LinkMore data={data} />
        </div>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
