/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@root/config');
import plonevoltoSlate from '@plone/volto-slate';
import affiniticvolto from '@affinitic/volto';
import eeacmsvoltoOpenlayersMap from '@eeacms/volto-openlayers-map';
import kitconceptvoltoBlocksGrid from '@kitconcept/volto-blocks-grid';
import eeacmsvoltoObjectWidget from '@eeacms/volto-object-widget';
import eeacmsvoltoBlockDivider from '@eeacms/volto-block-divider';

const addonsInfo = [{"modulePath":"/volto/node_modules/@plone/volto/packages/volto-slate/src","packageJson":"/volto/node_modules/@plone/volto/packages/volto-slate/package.json","version":"16.21.0","isPublishedPackage":false,"isRegisteredAddon":false,"name":"@plone/volto-slate","addons":[]},{"name":"@affinitic/volto","version":"1.2.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/volto/node_modules/@affinitic/volto/src","packageJson":"/volto/node_modules/@affinitic/volto/package.json","addons":[]},{"name":"@eeacms/volto-openlayers-map","version":"0.3.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/volto/node_modules/@eeacms/volto-openlayers-map/src","packageJson":"/volto/node_modules/@eeacms/volto-openlayers-map/package.json","addons":[]},{"name":"@kitconcept/volto-blocks-grid","version":"5.2.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/volto/node_modules/@kitconcept/volto-blocks-grid/src","packageJson":"/volto/node_modules/@kitconcept/volto-blocks-grid/package.json","addons":[]},{"name":"@eeacms/volto-object-widget","version":"5.0.1","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/volto/node_modules/@eeacms/volto-object-widget/src","packageJson":"/volto/node_modules/@eeacms/volto-object-widget/package.json","addons":[]},{"name":"@eeacms/volto-block-divider","version":"5.0.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/volto/node_modules/@eeacms/volto-block-divider/src","packageJson":"/volto/node_modules/@eeacms/volto-block-divider/package.json","addons":[]}];
export { addonsInfo };

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [plonevoltoSlate, affiniticvolto, eeacmsvoltoOpenlayersMap, kitconceptvoltoBlocksGrid, eeacmsvoltoObjectWidget, eeacmsvoltoBlockDivider];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
