import React, { Component } from 'react'
import { SidebarPortal, Icon} from '@plone/volto/components';
import penSVG from '@plone/volto/icons/pen.svg';
import Body from './Body';
import MapBannerData from './MapBannerData'
class Edit extends React.Component {
  render() {
    return (
      <>
      <Body data={this.props.data} id={this.props.block} />
      <SidebarPortal selected={this.props.selected}>
        <MapBannerData
            key={this.props.block}
            {...this.props}
            data={this.props.data}
            block={this.props.block}
            onChangeBlock={this.props.onChangeBlock}
          />
        </SidebarPortal>
      </>
    );
  }
}

export default Edit;